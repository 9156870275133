<template>
  <div class="container">
    <div class="d-flex flex-row justify-content-between">
      <a-button
        @click="$router.back()"
        style="border-radius: 20px; background-image: linear-gradient(to right, #1D2671 , #830051); color: white;"
        ><LeftOutlined /> Back</a-button
      >
      <a-button
        @click="toggleModal"
        style="border-radius: 20px; background-image: linear-gradient(to right, #1D2671 , #830051);color: white;"
        >Edit Schedule</a-button
      >
    </div>
    <a-modal v-model:visible="visible" title="Atur Jadwal" @ok="handleOk">
      <label for="frekuensi">Jenis Terapi: </label>
      <a-input v-model:value="jenisTerapi" placeholder="Nama Terapi" />
      <label for="frekuensi" class="mt-2">Frekuensi: </label>
      <a-select
        v-model:value="selectedFrekuensi"
        style="width: 100%; "
        @change="handleFrekuensiChange"
      >
        <a-select-option v-for="n in maxFrekuensi" :key="n" :value="n">
          {{ n }} Frekuensi
        </a-select-option>
      </a-select>
      <div v-for="(card, index) in cards" :key="index" class="mt-2">
        <a-input type="time" v-model:value="card.jam" @change="updateCardTime(index)" />
      </div>
    </a-modal>

    <div v-for="(card, index) in cards" :key="index">
      <div class="p-3">
        <div class="row" :class="$style['konsumsi-section']">
          <div
            class="col-4 d-flex flex-column flex-sm-row  align-items-center justify-content-center"
            style="background: linear-gradient(to right, #54BB9D, #28668B); border-radius: 20px 0 0 20px;"
          >
            <img src="../../assets/img/jam.png" alt="" :class="$style['img-clock']" />
            <div class="ml-0 ml-sm-3 mt-1 ">
              <p class="text-white m-0" :class="$style['clock-text']">
                {{ card.jam ? card.jam : '-- : --' }}
              </p>
            </div>
          </div>
          <div
            class="col-8 d-flex align-items-center justify-content-start"
            :class="$style['konsumsi-kanan']"
          >
            <div class="d-flex align-items-center justify-content-center">
              <img
                src="../../assets/img/pil.png"
                alt=""
                :class="$style['img-obat']"
                v-if="dataProgram === 'TAGRISSO' || dataProgram === 'CALQUENCE'"
              />
              <img
                src="../../assets/img/jarum_suntik.png"
                alt=""
                :class="$style['img-obat']"
                v-if="
                  dataProgram === 'FASLODEX' ||
                    dataProgram === 'IMFINZI' ||
                    dataProgram === 'IMJUDO'
                "
              />
              <img
                src="../../assets/img/kapsul.png"
                alt=""
                :class="$style['img-obat']"
                v-if="dataProgram === 'LYNPARZA'"
              />
              <div class="ml-4">
                <p class="m-0" :class="$style['text-obat']">Setiap Hari</p>
                <p class="m-0" :class="$style['text-obat']">
                  <strong>Minum Obat Tagrisso</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPatientProgram } from '../../services/axios/pulih'
import { useRouter } from 'vue-router'
import {
  ExclamationCircleOutlined,
  CheckCircleTwoTone,
  UploadOutlined,
  LeftOutlined,
} from '@ant-design/icons-vue'
export default {
  components: {
    LeftOutlined,
  },
  data() {
    const visible = false
    const jenisTerapi = ''
    const router = useRouter()
    const dataProgram = []
    return {
      dataProgram,
      router,
      jenisTerapi,
      visible,
      selectedFrekuensi: 1,
      maxFrekuensi: 10, // Misalkan maksimal frekuensi adalah 10
      cards: [{ jam: '' }],
    }
  },
  async mounted() {
    console.log('inidischedule')
    const userData = JSON.parse(localStorage.getItem('userData'))
    const jadwals = JSON.parse(localStorage.getItem('jadwals'))
    const res = await getPatientProgram(userData.user.code)
    this.dataProgram = res.programs[0].programType.name
    console.log(this.dataProgram)
    // console.log(res.programs[0].programType.defaultSchedules)
    if (jadwals.length) {
      this.cards = jadwals
      this.selectedFrekuensi = jadwals.length
    } else {
      console.log('masuksininlawanjadwal')
      if (res.programs[0].programType.defaultSchedules.length) {
        console.log('masuksinidefatulsscheduleada')
        this.cards = [{ jam: res.programs[0].programType.defaultSchedules[0].time }]
      }
    }
    this.jenisTerapi = res.programs[0].programType.name

    console.log(res, 'inidata')
    console.log(this.cards)
  },
  methods: {
    toggleModal() {
      this.visible = !this.visible
    },
    handleBack() {
      this.router.push('/dashboard')
    },
    handleFrekuensiChange() {
      const newFrekuensi = this.selectedFrekuensi
      const currentFrekuensi = this.cards.length

      // Jika frekuensi baru lebih besar, tambahkan card baru
      if (newFrekuensi > currentFrekuensi) {
        const tambahan = newFrekuensi - currentFrekuensi
        for (let i = 0; i < tambahan; i++) {
          this.cards.push({ jam: '' })
        }
      } else if (newFrekuensi < currentFrekuensi) {
        // Jika frekuensi baru lebih kecil, hapus card yang tidak perlu
        this.cards.splice(newFrekuensi)
      }
    },
    updateCardTime(index) {
      // Fungsi untuk memperbarui waktu pada card tertentu
      console.log(`Waktu pada card ${index + 1} diperbarui menjadi ${this.cards[index].jam}`)
    },
    handleOk() {
      console.log(this.cards, 'inisemuajawdals')
      this.cards = this.cards.map(e => {
        e.checked = false
        e.isAlarm = false
        return e
      })
      const stringifiedCards = JSON.stringify(this.cards)
      // console.log('stringifiedcars', stringifiedCards)
      localStorage.setItem('jadwals', stringifiedCards)
      this.visible = false
    },
  },
}
</script>

<style scoped></style>

<style lang="scss" module>
@import '@/components/Auth/style.module.scss';
.clock-text {
  font-size: 14px;
}
.card {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.main-menu-item {
  width: 100%;
  height: auto;
  object-fit: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.main-menu-item:hover {
  transform: scale(1.05);
  /* Slightly increase the size */
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.24);
  /* Optionally enhance the shadow */
}

.title-section {
  font-size: 20px;
  color: #4f125f;
  width: 200px;
}

.custom-card {
  position: relative;
  overflow: hidden;
  /* Memastikan isi tidak melebihi batas card */
  border-radius: 17px;
}

.custom-card img {
  width: 100%;
  height: auto;
  /* Sesuaikan dengan kebutuhan, bisa juga height: 100% jika perlu */
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, #800152, transparent);
  width: 100%;
  height: 100%;
  /* Atur sesuai kebutuhan untuk efek gradient */
  z-index: 1;
}

.card-title {
  position: absolute;
  bottom: 10px;
  left: 30px;
  color: white;
  z-index: 2;
  /* Pastikan teks berada di atas overlay */
  width: 80%;
  /* Sesuaikan lebar teks */
}

/* Menargetkan elemen checkbox internal secara global */
:global(.ant-checkbox-inner) {
  width: 20px !important;
  /* Mengatur lebar */
  height: 20px !important;
  /* Mengatur tinggi */
  border-radius: 3px !important;
  /* Opsional: Menghapus border-radius */

  /* Center horizontal */
  border: 1px solid #707072 !important;
}

/* Opsi tambahan: Menyesuaikan ukuran tanda centang jika perlu */
:global(.ant-checkbox-inner::after) {
  /* Atur lebar tanda centang */
  // width: 20px !important;
  // height: 20px !important;
  /* Atur tinggi tanda centang */
  /* Pusatkan secara vertikal */
  // border-radius: 8px !important;
  display: flex !important;
  /* Menggunakan flexbox untuk align centang */
  align-items: center !important;
  /* Center vertical */
  justify-content: center !important;
  // top: 20px !important;
  left: 5px !important;
  // transform: scale(1) !important;
  // rotate: 40deg;
  /* Menyesuaikan posisi dan skala */
}

.custom-input-email-login {
  border: none !important;
  border-bottom: 1px solid #e4e9f0 !important;
  border-radius: 0 !important;

  &:focus {
    border-bottom: 1px solid #800152 !important;
  }

  &:hover {
    border-bottom: 1px solid #800152 !important;
  }
}

.custom-input-password-login {
  border: none !important;
  border-bottom: 1px solid #e4e9f0 !important;
  border-radius: 0 !important;

  &:focus-within {
    border-bottom: 1px solid #800152 !important;
  }

  &:hover {
    border-bottom: 1px solid #800152 !important;
  }
}

.custom-forget-pass {
  color: #800152 !important;
  border-bottom: 1px solid transparent;
  transition: 0.3s all ease-in-out;

  &:hover {
    border-bottom: 1px solid #800152 !important;
  }
}

.custom-register-link {
  color: #800152 !important;
  font-weight: 700;
  border-bottom: 1px solid transparent;
  transition: 0.3s all ease-in-out;

  &:hover {
    border-bottom: 1px solid #800152 !important;
  }
}

.custom-form-item {
  label::before {
    content: '' !important;
  }
}

.custom-button-yes {
  // width: 100%;
  height: 20px !important;
  font-weight: 500;
  font-size: 8px !important;
  text-transform: uppercase;
  background: linear-gradient(to right, #eda702, #c76b1e) !important;
  border: 1px solid #eda702 !important;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #eda702 !important;
    background: transparent !important;
  }
}

.custom-button-no {
  // width: 100%;
  height: 20px !important;
  font-weight: 500;
  font-size: 8px !important;
  text-transform: uppercase;
  background: linear-gradient(to right, #800251, #ba0672) !important;
  transition: all 0.3s ease-in-out;
  border: 1px solid #800251 !important;

  &:global(.ant-btn-primary:hover) {
    border: 1px solid #800251 !important;
  }

  &:hover {
    color: #800251 !important;
    background: transparent !important;
    border: 1px solid #800251 !important;
  }
}

.custom-container-login {
  width: 75% !important;
}

.custom-text-login {
  color: white;
}

@media (max-width: 960px) {
  .custom-container-login {
    width: 100% !important;
  }
}

.step-title {
  font-size: 18px;
}

.img-banner {
  width: 130px;
  height: 80px;
}

.banner-title {
  font-size: 10px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
}

.line {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
  background-color: #4f4f4f;
  height: 1px;
}

.img-step {
  width: 32px;
  height: 32px;
}

.step p {
  font-size: 8px;
}

.img-clock {
  width: 32px;
  height: 32px;
}

.konsumsi-section {
  height: 70px;
}

.img-obat {
  width: 32px;
  height: 32px;
}

.text-obat {
  font-size: 12px;
}

.konsumsi-kanan {
  background-color: #ffffff;
  border-radius: 0 20px 20px 0;
  padding: 20px 30px 20px 30px;
}

.article-item {
  width: 280px;
  height: 130px;
  border-radius: 20px;
  margin-top: 10px;
}

.menu-title {
  font-size: 10px;
  height: auto;
}

//DEKSTOP
@media screen and (min-width: 1440px) {
  .clock-text {
    font-size: 32px;
  }
  .main-menu-item {
    width: 100%;
    height: auto;
    object-fit: cover;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 30px;
  }

  .title-section {
    font-size: 32px;
    color: #4f125f;
    width: auto;
  }

  .img-banner {
    width: 327.75px;
    height: 205.68px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 50px;
  }

  .banner-title {
    font-size: 28px;
    width: 80%;
    margin-bottom: 50px;
  }

  .custom-button-yes {
    height: 60px !important;
    width: 80%;
    font-size: 24px !important;
  }

  .custom-button-no {
    height: 60px !important;
    width: 80%;
    font-size: 24px !important;
    margin-top: 5px;
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;
  }

  .line {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    background-color: #4f4f4f;
    height: 1px;
  }

  .img-step {
    width: 64px;
    height: 64px;
  }

  .step p {
    font-size: 16px;
  }

  .title-step {
    height: 240px;
  }

  .step-title {
    font-size: 32px;
    width: 130px;
    text-align: center;
  }

  .menu-title {
    font-size: 18px;
  }

  .img-clock {
    width: 88px;
    height: 85px;
  }

  .konsumsi-section {
    height: 200px;
  }

  .img-obat {
    width: 65px;
    height: 65px;
  }

  .text-obat {
    font-size: 24px;
  }

  .konsumsi-kanan {
    background-color: #ffffff;
    border-radius: 0 20px 20px 0;
    padding: 50px 70px 50px 70px;
  }

  .article-item {
    width: 400px;
    height: 200px;
    border-radius: 20px;
    margin-top: 10px;
  }

  /* Menargetkan elemen checkbox internal secara global */
  :global(.ant-checkbox-inner) {
    width: 56px !important;
    /* Mengatur lebar */
    height: 56px !important;
    /* Mengatur tinggi */
    border-radius: 8px !important;
    /* Opsional: Menghapus border-radius */

    /* Center horizontal */
    border: 1px solid #707072 !important;
  }

  /* Opsi tambahan: Menyesuaikan ukuran tanda centang jika perlu */
  :global(.ant-checkbox-inner::after) {
    /* Atur lebar tanda centang */
    // width: 20px !important;
    // height: 20px !important;
    /* Atur tinggi tanda centang */
    /* Pusatkan secara vertikal */
    // border-radius: 8px !important;
    display: flex !important;
    /* Menggunakan flexbox untuk align centang */
    align-items: center !important;
    /* Center vertical */
    justify-content: center !important;
    top: 20px !important;
    left: 24px !important;
    transform: scale(3) !important;
    rotate: 40deg;
    /* Menyesuaikan posisi dan skala */
  }
}

//TABLET
@media screen and (max-width: 1439px) and (min-width: 768px) {
  .clock-text {
    font-size: 24px;
  }
  .main-menu-item {
    width: 100%;
    height: auto;
    object-fit: cover;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 20px;
  }

  .title-section {
    font-size: 32px;
    color: #4f125f;
    width: auto;
  }

  .img-banner {
    width: 230px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .step-title {
    font-size: 32px;
    // width: 130px;
    text-align: center;
  }

  .banner-title {
    font-size: 16px;
    width: 80%;
  }

  .custom-button-yes {
    height: 30px !important;
    width: 80%;
    font-size: 12px !important;
  }

  .custom-button-no {
    height: 30px !important;
    width: 80%;
    font-size: 12px !important;
    margin-top: 5px;
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70px;
  }

  .line {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70px;
    background-color: #4f4f4f;
    height: 1px;
  }

  .img-step {
    width: 50px;
    height: 50px;
  }

  .step p {
    font-size: 12px;
  }

  .title-step {
    height: 240px;
  }

  .img-clock {
    width: 48px;
    height: 45px;
  }

  .konsumsi-section {
    height: 100px;
  }

  .article-item {
    width: 280px;
    height: 130px;
    border-radius: 20px;
    margin-top: 10px;
  }
}

.doctor-prescription {
  /* max-width: 400px; */
  margin: auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: white;
  /* text-align: center; */
}

.doctor-info div {
  margin-bottom: 10px;
  text-align: left;
}

.upload-section {
  /* margin: 20px 0; */
}

.title {
  color: #333;
}

.label-col {
  text-align: right;
  padding-right: 15px;
}

.form-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.col-12 {
  flex: 1;
}

.col-sm-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-sm-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}
</style>
